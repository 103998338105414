<template>
  <v-card
    class="card-with-animation shadow-none therapist-card fill-height d-flex flex-column"
    style="max-width: 100%"
  >

    <div class="d-flex px-lg-5 px-8 pt-2">
      <UserAvatar :user="therapist" :size="75" />
      <div class="ms-4">
        <h2 class="body-1 font-weight-bold text-start">
          {{ getUserName(therapist) }}
          <!-- {{therapist.email}} -->
        </h2>
        <h5 class="caption opacity-75 text-start">
          {{ therapistCategoryTitle(therapist) }}
        </h5>
        <h3 class="warning--text text-start" v-if="i < 3">{{ $t("Best Match") }}</h3>
      </div>
    </div>
    <v-card-text class="px-lg-5 pt-2 px-8 pb-0 d-flex flex-column">
      <div class="d-flex align-center">
        <div class="w-100">
          <v-list dense>
            <v-list-item class="text-start mb-0 px-0">
              <img src="./../../assets/check.svg" width="40" alt="" />

              <v-list-item-content class="ms-4">
                <v-list-item-subtitle class="caption opacity-75">
                  {{ $t("Speciality") }}
                </v-list-item-subtitle>

                <p class="mb-0 caption font-weight-bold body-2">
                  {{ showFirstTwoSpecialities }}
                  <span v-if="getMoreCount > 0 && !showMore" class="me-2"
                    >...</span
                  >
                  <v-btn
                    text
                    color="primary"
                    plain
                    :ripple="false"
                    class="text-decoration-underline caption pa-0"
                    @click="showMoreAction"
                    v-if="getMoreCount > 0 && !showMore"
                  >
                    + {{ getMoreCount }} {{ $t("More") }}
                  </v-btn>
                </p>

                <div class="d-flex flex-wrap align-start flex-column">
                  <p
                    class="mb-0 caption font-weight-bold body-2"
                    v-for="(item, i) in otherSpecialities"
                    :key="i"
                    v-show="showMore && i !== 0"
                  >
                    {{ $t(item) }}
                    <template v-if="i < otherSpecialities.length - 1">{{
                      " "
                    }}</template>
                  </p>
                  <v-btn
                    text
                    color="primary"
                    plain
                    @click="showMoreAction"
                    class="pa-0 caption"
                    v-if="getMoreCount > 0 && showMore"
                  >
                    {{ $t("Show less") }}
                    <v-icon>mdi-chevron-up</v-icon>
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="text-start mb-0 px-0">
              <img src="./../../assets/star.svg" width="40" alt="" />

              <v-list-item-content class="ms-4">
                <v-list-item-subtitle class="caption opacity-75">
                  {{ $t("Experience") }}
                </v-list-item-subtitle>
                <v-list-item-title class="font-weight-bold caption mt-1">
                  {{
                    therapistExperienceYears(therapist) +
                    "+" +
                    " " +
                    $t("years of experience")
                  }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="text-start mb-0 px-0">
              <img src="./../../assets/flag.svg" width="40" alt="" />

              <v-list-item-content class="ms-4">
                <v-list-item-subtitle class="caption opacity-75">{{
                  $t("Language")
                }}</v-list-item-subtitle>
                <v-list-item-title class="font-weight-bold">
                  <span v-if="!showLanguagesMore">{{ showFirstTwoLanguages }}</span>
                  <span v-if="showLanguagesMore">
                    <span v-for="(lang, i) in therapistLanguageSpeak(therapist)" :key="lang + i" class="font-weight-bold caption">
                      {{ $t(lang) }}<template v-if="i < therapistLanguageSpeak(therapist).length - 1">,</template>
                    </span>
                  </span>
                  <v-btn
                    text
                    color="primary"
                    plain
                    @click="showLanguagesMoreAction"
                    class="pa-0 caption"
                    v-if="getLanguagesMoreCount > 0"
                  >
                    {{ showLanguagesMore ? $t("Show less") : ('+ ' + getLanguagesMoreCount + ' ' + $t("More")) }}
                  </v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              class="text-start mb-0 px-0"
              v-show="therapistEducation(therapist)"
              v-for="(edu, i) in therapistEducation(therapist)"
              :key="i + 'e'"
            >
              <img src="./../../assets/master-hat.svg" width="40" alt="" />

              <v-list-item-content class="ms-4">
                <v-list-item-subtitle class="caption opacity-75">
                  {{ $t("Degree") }}
                </v-list-item-subtitle>
                <v-list-item-title>
                  <p
                    class="caption black--text font-weight-bold mt-1 text-elipsis mb-0"
                    v-if="!$vuetify.rtl"
                  >
                    {{ edu.degreeName || "-" }}
                  </p>
                  <p
                    class="caption black--text font-weight-bold mt-1 text-elipsis mb-0"
                    v-else
                  >
                    {{ edu.degreeNameAr || edu.degreeName }}
                  </p>
                  <p
                    class="caption black--text font-weight-bold mt-1 text-elipsis mb-0"
                    v-if="!$vuetify.rtl"
                  >
                    {{ edu.institution || "-" }}
                  </p>
                  <p
                    class="caption black--text font-weight-bold mt-1 text-elipsis mb-0"
                    v-else
                  >
                    {{ edu.institutionAr || edu.institution }}
                  </p>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="text-start mb-0 px-0">
              <img src="./../../assets/certificate.svg" width="40" alt="" />

              <v-list-item-content class="ms-4">
                <v-list-item-subtitle class="caption opacity-75">
                  {{ $t("Trainings & Certification") }}
                </v-list-item-subtitle>
                <!-- <v-list-item-title
                  class="font-weight-bold caption mt-1"
                  v-for="(val, key, i) in therapistTrainingCertification(
                    therapist
                  )"
                  v-show="val"
                  :key="i"
                >
                  {{ $t(trainingCertificationMapper[key]) }}
                </v-list-item-title> -->

                <p class="mb-0 caption font-weight-bold body-2">
                  {{ showFirstTwoTrainings }}
                  <span
                    v-if="getTrainingsMoreCount > 0 && !showTrainingsMore"
                    class="me-2"
                    >...</span
                  >
                  <v-btn
                    text
                    color="primary"
                    plain
                    :ripple="false"
                    class="text-decoration-underline caption pa-0"
                    @click="showTrainingsMoreAction"
                    v-if="getTrainingsMoreCount > 0 && !showTrainingsMore"
                  >
                    + {{ getTrainingsMoreCount }} {{ $t("More") }}
                  </v-btn>
                </p>

                <div class="d-flex flex-wrap align-start flex-column">
                  <p
                    class="mb-0 caption font-weight-bold body-2"
                    v-for="(item, i) in otherTrainings"
                    :key="i"
                    v-show="showTrainingsMore && i !== 0"
                  >
                    {{ $t(item) }}
                    <template v-if="i < otherTrainings.length - 1">{{
                      " "
                    }}</template>
                  </p>
                  <v-btn
                    text
                    color="primary"
                    plain
                    @click="showTrainingsMoreAction"
                    class="pa-0 caption"
                    v-if="getTrainingsMoreCount > 0 && showTrainingsMore"
                  >
                    {{ $t("Show less") }}
                    <v-icon>mdi-chevron-up</v-icon>
                  </v-btn>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </div>

      <v-divider class="mt-4"></v-divider>
      <h4 class="mt-5 text-start">{{ $t("Get to know me") }}</h4>
      <p class="font-size-14 opacity-50 text-pre-wrap text-break text-start">
        <template
          v-if="therapistBio(therapist).length > 400 && !viewProfileAction"
        >
          {{ therapistBio(therapist).slice(0, 120) + "..." }}
        </template>
        <template v-else>{{ therapistBio(therapist) }} </template>
        <v-btn
          small
          :ripple="false"
          plain
          @click="viewProfile(i)"
          class="mb-1 ms-1 px-0 font-weight-bold text-decoration-underline"
          text
          color="primary"
        >
          <template v-if="!viewProfileAction">
            {{ $t("Show More") }}
          </template>
          <template v-else>
            {{ $t("Show less") }}
          </template>
        </v-btn>
      </p>
    </v-card-text>
    <v-card-actions class="mt-auto px-5 mb-2 flex-column">
      <v-btn
        color="primary"
        block
        class="py-4 mb-3 select-btn"
        :disabled="therapist._id === myTherapistId"
        @click="selectTherapist(therapist)"
      >
        {{ $t("Select") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import TherapistMixin from "@/mixins/TherapistMixin";
import UserMixin from "@/mixins/UserMixin";
import UserAvatar from "@/components/UserAvatar";
import { mapActions } from "vuex";
import { therapyTypeToDisorderCategoryMapper } from "@/constants/therapyType";

export default {
  name: "TherapistCard",
  props: {
    therapist: {
      required: true,
    },
    i: {
      required: true,
    },
    therapistsData: {
      required: true,
    },
  },
  components: {
    UserAvatar,
  },

  mixins: [UserMixin, TherapistMixin],

  data: () => {
    return {
      viewProfileAction: false,
      showTrainingsMore: false,
      showMore: false,
      showLanguagesMore: false,
      therapistIndex: null,
      changeTherapistReason: "",
      selectedTherapist: null,
      trainingCertificationMapper: {
        traumaBased: "Trauma Based Therapy",
        dialecticBehavioral: "Dialectical Behavior Therapy (DBT)",
        cognitiveBehavioral: "Cognitive Behavioral Therapy (CBT)",
        psychodynamic: "Psychodynamic Therapy",
        psychoanalysis: "Psychoanalysis",
        integrative: "Integrative Therapy",
      },
    };
  },

  computed: {
    // itemsCount() {
    //   return this?.therapistsData?.length || 0;
    // },

    showFirstTwoLanguages() {
    let languages = this.therapistLanguageSpeak(this.therapist);
    return languages.slice(0, 2).map(lang => this.$t(lang)).join(', ');
    },

    otherLanguages() {
      let languages = this.therapistLanguageSpeak(this.therapist);
      return languages.slice(2);
    },

    getLanguagesMoreCount() {
      let languages = this.therapistLanguageSpeak(this.therapist);
      return languages.length > 2 ? languages.length - 2 : 0;
    },

    currentTherapistSpeciality() {
      return this.therapistSpeciality(
        this.therapist,
        therapyTypeToDisorderCategoryMapper[this.myTherapyType]
      );
    },

    showFirstTwoSpecialities() {
      let specialities = this.currentTherapistSpeciality;
      return `${this.$t(specialities[0])}`;
    },

    otherSpecialities() {
      return this.currentTherapistSpeciality;
    },

    getMoreCount() {
      let specialities = this.currentTherapistSpeciality;
      return specialities.length - 1;
    },

    showFirstTwoTrainings() {
      let trainData = this.therapistTrainingCertification(this.therapist);

      let trainings = [];
      for (const [key, value] of Object.entries(trainData)) {
        if (value) {
          trainings.push(this.trainingCertificationMapper[key]);
        }
      }

      return `${this.$t(trainings[0])}`;
    },

    otherTrainings() {
      let trainData = this.therapistTrainingCertification(this.therapist);

      let trainings = [];
      for (const [key, value] of Object.entries(trainData)) {
        if (value) {
          trainings.push(this.trainingCertificationMapper[key]);
        }
      }
      return trainings;
    },
    getTrainingsMoreCount() {
      let trainData = this.therapistTrainingCertification(this.therapist);

      let trainings = [];
      for (const [key, value] of Object.entries(trainData)) {
        if (value) {
          trainings.push(this.trainingCertificationMapper[key]);
        }
      }
      return trainings.length - 1;
    },
  },

  methods: {
    ...mapActions("disorder", {
      disorderGetAll: "getAll",
    }),

    showLanguagesMoreAction() {
    this.showLanguagesMore = !this.showLanguagesMore;
  },

    viewProfile() {
      // this.$emit("openTherapistProfile", index);
      this.viewProfileAction = !this.viewProfileAction;
    },

    selectTherapist(therapist) {
      this.$emit("selectTherapist", therapist);
    },
    closeMore() {
      this.showMore = false;
      this.viewProfileAction = false;
      this.showTrainingsMore = false;
    },
    // onAddReason() {
    //   this.$refs.ChangeTherapistConfirmRef.dialog = true;
    // },

    // onConfirmChangeTherapist() {
    //   if (this.myTherapistId !== this.selectedTherapist._id) {
    //     this.loading = true;

    //     this.changeTherapistForUser({
    //       id: this.selectedTherapist._id,
    //       reason: this.changeTherapistReason,
    //     }).then(() => {
    //       this.loading = false;
    //       this.$nextTick(() => {
    //         this.$refs.ChangeTherapistPrivacyRef.dialog = true;
    //       });
    //     });
    //   }
    // },

    // openDialog() {
    //   this.therapistIndex = this.i;
    //   console.log(this.therapistIndex, "indeeex");
    //   console.log(this.i);
    //   this.$refs.viewTherapistProfileRef.open();
    // },

    // selectTherapist(therapist) {
    //   if (this.goToPricing) {
    //     this.$router.push({
    //       name: "Pricing",
    // params: { therapistId: therapist._id },
    //     });
    //   } else {
    //     this.$refs.ChangeTherapistReasonRef.dialog = true;
    //     this.selectedTherapist = therapist;
    //   }
    // },

    showMoreAction() {
      this.showMore = !this.showMore;
    },
    showTrainingsMoreAction() {
      this.showTrainingsMore = !this.showTrainingsMore;
    },
  },
  mounted() {
    this.disorderGetAll().catch((err) =>
      console.error("Error on Disorder get", err)
    );
  },
};
</script>

<style scoped lang="scss">
::v-deep.v-btn {
  height: auto !important;
  &.select-btn {
    box-shadow: 0px 13px 21px rgba(52, 117, 178, 0.35);
    border-radius: 15px !important;
  }
}
::v-deep.v-divider {
  border-color: #a4a4a4 !important;
}
.text-elipsis {
  white-space: normal !important;
}
/* ::v-deep.v-card {
  max-width: 300px !important;
} */
</style>
