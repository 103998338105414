<template>
  <v-avatar :size="size" :color="color" :class="avatarClass">
    <img
      v-if="avatar"
      v-show="avatarShow"
      @error="imageNotLoaded = true"
      @load="imageNotLoaded = false"
      :src="avatarUrl"
      :alt="user.email"
    />
    <template v-if="!avatarShow">
      <span
        v-if="userName"
        class="white--text"
        :style="'font-size:' + size / 2.44 + 'px'"
      >
        {{ userName }}
      </span>
      <v-icon v-else :color="iconColor" :size="size">
        mdi-account-circle
      </v-icon>
    </template>
  </v-avatar>
</template>

<script>
export default {
  name: "UserAvatar",
  props: {
    user: {
      required: true,
    },
    avatarClass: {
      type: String,
      default: "",
    },
    size: {
      type: Number,
      default: 36,
    },
    color: {
      type: [String, undefined],
      default: () => "primary",
    },
    iconColor: {
      type: [String, undefined],
      default: undefined,
    },
  },
  data: () => {
    return {
      imageNotLoaded: false,
    };
  },
  computed: {
    avatarUrl() {
      return (
        process.env.VUE_APP_SERVER_PUBLIC_URL +
        "/" +
        this?.user?.profile?.avatar
      );
    },

    userName() {
      return this?.user?.profile?.anonymous
        ? this?.user?.profile?.username[0]
        : this?.user?.profile?.firstName[0];
    },

    avatar() {
      return this.user?.profile?.avatar;
    },

    avatarShow() {
      return this.avatar && !this.imageNotLoaded;
    },
  },
};
</script>

<style scoped>
img {
  object-fit: cover;
}
.v-toolbar__content .v-avatar::v-deep,
.v-avatar::v-deep {
  border: 1px solid var(--v-white-base) !important;
}
</style>
