<template>
  <v-app-bar
    style="height: 65px"
    color="transparent"
    clipped-left
    flat
    absolute
    top
    class="not-logged-navbar"
  >
    <v-container class="px-0 px-sm-3">
      <v-toolbar-title class="d-flex align-center">
        <v-btn
          v-if="showBackButton"
          class="position-absolute back-btn"
          icon
          small
          plain
          :ripple="false"
          @click="onBack"
          ><v-icon>mdi-arrow-left</v-icon></v-btn
        >
        <router-link
          text
          :ripple="false"
          :to="{ name: 'Calendar' }"
          style="line-height: 1"
        >
          <logoSvg class="logo-bigger" />
        </router-link>
        <v-spacer></v-spacer>
        <v-menu offset-y class="d-lg-flex d-none" v-model="languageMenu">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              plain
              class="language-btn"
              :ripple="false"
              v-bind="attrs"
              v-on="on"
              @click="languageMenu = !languageMenu"
            >
              <v-icon left>mdi-web</v-icon>
              <span v-if="!$vuetify.rtl">Eng</span>
              <span v-else>عربى</span>
              <v-icon right v-if="!languageMenu">mdi-chevron-down</v-icon>
              <v-icon right v-else>mdi-chevron-up</v-icon>
            </v-btn></template
          >
          <v-list dense>
            <!-- <v-list-item dense class="pb-0">
              <h5 class="font-size-13 mb-0 font-weight-bold">
                {{ $t("Language") }}:
              </h5>
            </v-list-item> -->
            <v-list-item dense @click="onChangeLang('en')">
              English
              <!-- <v-chip-group column :value="lang"> -->
              <!-- <v-chip
                  small
                  filter
                  outlined
                  value="en"
                 
                  ></v-chip
                > -->
              <!-- <v-chip
                  small
                  filter
                  outlined
                  value="ar"
                  @click="onChangeLang('ar')"
                >
                  </v-chip
                > -->
              <!-- </v-chip-group> -->
            </v-list-item>
            <v-list-item dense @click="onChangeLang('ar')">عربى</v-list-item>
            <!-- <v-divider></v-divider>
            <v-list-item dense class="mt-4 pb-0">
              <h5 class="font-size-13 mb-0 font-weight-bold">
                {{ $t("Theme") }}:
              </h5>
            </v-list-item> -->
            <!-- <v-list-item class="pt-0 pb-2">
              <v-switch
                :input-value="themeDark"
                @click="changeTheme"
                class="mt-0"
                :ripple="false"
                hide-details
              >
                <template v-slot:label>
                  <span class="font-size-14">{{ $t("Dark mode") }}</span>
                </template>
              </v-switch>
            </v-list-item> -->
          </v-list>
        </v-menu>
        <v-btn
          v-if="adminToken && accessToken !== adminToken"
          class="ml-6"
          small
          color="primary"
          outlined
          @click="backToAdmin"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          Back To Admin
        </v-btn>
        <v-btn
          color="primary"
          class="py-md-3 py-2 px-md-6 px-3 font-weight-bold shadow-none"
          v-if="!isLoggedIn"
          :ripple="false"
          :to="{ name: `${showSingIn ? 'Services' : 'Login'}` }"
        >
          <template v-if="showSingIn">{{ $t("Sign up") }}</template>
          <template v-else>{{ $t("Sign in") }}</template>
        </v-btn>
        <v-btn
          color="primary"
          class="py-md-3 py-2 px-md-4 px-sm-3 px-2 font-weight-bold shadow-none"
          v-else-if="isLoggedIn && !adminToken"
          @click="logout"
          :ripple="false"
        >
          {{ $t("Sign out") }}
        </v-btn>
      </v-toolbar-title>
    </v-container>
  </v-app-bar>
</template>

<script>
import logoSvg from "@/components/svg/Logo";
import UserMixin from "@/mixins/UserMixin";
import TherapistMixin from "@/mixins/TherapistMixin";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "NoUserNavbar",
  mixins: [UserMixin, TherapistMixin],
  props: {
    showBackButton: {
      default: true,
    },
    backButtonAction: {
      default: null,
    },
  },

  components: { logoSvg },

  data: () => {
    return {
      mobileMenu: false,
      languageMenu: false,
      navbarButtons: [
        { name: "Our Services", to: "https://talktime.ae/#services" },
        { name: "Pricing", to: "https://talktime.ae/#pricing" },
        { name: "Our Therapists", to: "https://talktime.ae/therapists/" },
        { name: "Resources", to: "https://talktime.ae/resources/#content" },
        { name: "Join Our Network", to: "https://talktime.ae/network/" },
      ],
    };
  },
  computed: {
    ...mapGetters(["lang", "themeDark"]),
    ...mapGetters("user", ["isLoggedIn"]),

    showSingIn() {
      return this.$route.name === "Login";
    },
  },

  methods: {
    ...mapMutations(["changeLang", "changeThemeDark"]),
    ...mapActions("user", ["logout"]),
    ...mapMutations("user", ["setAccessToken"]),

    changeTheme() {
      this.changeThemeDark(!this.themeDark);
    },

    onChangeLang(lang) {
      this.changeLang(lang);
    },

    onBack() {
      typeof this.backButtonAction === "function"
        ? this.backButtonAction()
        : this.$router.go(-1);
    },

    backToAdmin() {
      this.setAccessToken(this.adminToken);
      location.replace("/admin/access-users");
    },
  },
  mounted() {
    // console.log(this.$router)
    // console.log(this.isLoggedIn);
    // console.log(this.$store.getters.accessToken);
  },
};
</script>

<style scoped lang="scss">
::v-deep.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover)
  .v-btn__content {
  opacity: 1;
}

::v-deep.v-btn:not(.v-btn--plain) {
  max-height: 45px !important;
}
.shadow-none {
  box-shadow: none !important;
}
</style>
