<template>
  <v-dialog v-model="dialog" persistent width="700">
    <v-card class="rounded-lg">
      <v-btn
        :to="{ name: 'MyTherapist' }"
        elevation="2"
        icon
        class="close-icon pa-0"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-card-text class="pa-8">
        <p class="text-center mb-0 mt-4">
          You have changed your therapist.
          <br />
          Your new therapist is
        </p>
        <p class="text-center secondary--text font-weight-bold">
          {{ getUserName(selectedTherapist) }}
        </p>
        <p class="text-center font-weight-medium">
          TalkTime values your privacy and we will not share any previous <br />
          communication without your permission.
        </p>
      </v-card-text>

      <v-card-actions class="pb-6">
        <v-spacer></v-spacer>
        <v-btn color="primary" class="px-8 me-3" large @click="onContinue">
          {{ $t("Continue") }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import UserMixin from "@/mixins/UserMixin";

export default {
  name: "ChangeTherapistPrivacy",
  mixins: [UserMixin],

  props: {
    selectedTherapist: {
      required: true,
    },
  },

  data: () => {
    return {
      dialog: false,
    };
  },

  methods: {
    async onContinue() {
      await this.getMe();
      await this.$router.replace({ name: "MyTherapist" });
    },
  },
};
</script>
