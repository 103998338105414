<template>
  <div>
    <v-sheet
      flat
      class="caption pt-5"
      width="100%"
      absolute
      bottom
      color="transparent"
    >
      <v-container>
        <v-row class="mb-1">
          <v-col md="auto" cols="12" class="px-md-0">
            <logoSvg></logoSvg>
            <h3 class="body-2 mt-4 font-weight-bold">{{ $t("How can we help you ?") }}</h3>
            <p class="caption">{{ $t("Our customer care team is happy to assist you with your inquiries") }}</p>
            <a href="mailto:customercare@talktime.ae" class="me-6 mb-2"><v-icon color="primary">mdi-email-outline</v-icon><span class="ps-2">customercare@talktime.ae</span></a>
            <a href="tel:+971048369411" target="_blank">
              <v-icon color="primary" style="text-decoration: none;">mdi-phone</v-icon>
              <span class="ms-2">{{ $t("+971048369411") }}</span></a>
          </v-col>
        </v-row>
      </v-container>
      <v-sheet color="#E5F5F6">
        <v-container class="px-md-0">
          <v-row>
            <v-col>
              <p class="mb-0 opacity-75">
                Copyright © 2023 All Rights reserved TalkTime Mental Health Services
                Limited.
              </p>
            </v-col>
          </v-row>
        </v-container>
     </v-sheet>
    </v-sheet>
  </div>
</template>

<script>
import logoSvg from "@/components/svg/Logo";
export default {
  name: "",
  components: {
    logoSvg,
  },
  data: () => {
    return {
      emailAddress: "",
      usefulLinks: [
        {
          name: "Individual therapy",
          to: "https://talktime.ae/individual-therapy",
        },
        {
          name: "Couples Therapy",
          to: "https://talktime.ae/couples-therapy/",
        },
        { name: "Teen Therapy", to: "https://talktime.ae/teen-therapy/" },
        {
          name: "Employee Empowerment Program",
          to: "https://talktime.ae/corporate-empowerment/",
        },
      ],
      aboutItems: [
        { name: "Our Therapists", to: "https://talktime.ae/therapists/" },
        { name: "Resources", to: "https://talktime.ae/resources/" },
        { name: "FAQs", to: "https://talktime.ae/faq/" },
        { name: "Join Our Network", to: "https://talktime.ae/network/" },
        { name: "Privacy Policy", to: "https://talktime.ae/privacy-policy/" },
        { name: "User Terms", to: "https://talktime.ae/user-terms/" },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
::v-deep.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover)
  .v-btn__content {
  opacity: 1;
}
.v-list-item {
  font-size: 13px !important;
}
::v-deep.v-text-field .v-input__control .v-input__slot .v-label {
  font-size: 10px;
  font-weight: 500;
}
::v-deep.v-text-field .v-input__control .v-input__slot {
  font-size: 10px !important;
  /* padding-left: 30px !important; */
  padding-inline: 12px 0 !important;
  box-shadow: 0px 11px 28px rgba(0, 0, 0, 0.09);
  border-radius: 15px;
  .v-input__append-inner {
    margin-top: 1px !important;
  }
}

::v-deep.v-btn.follow-btn {
  height: 40px !important;
  width: 40px;
  min-width: auto !important;
  border-radius: 8px !important;
}
</style>
