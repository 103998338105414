<template>
  <svg
    width="100"
    height="21"
    viewBox="0 0 100 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M82.4854 15.9531V8.62586C82.4854 8.17059 82.4443 7.81981 82.3641 7.56979C82.2839 7.31976 82.1588 7.14064 81.9909 7.03056C81.8211 6.92047 81.6159 6.85517 81.3752 6.83278C81.1326 6.81039 80.8509 6.79919 80.5281 6.79919C80.2202 6.79919 79.9347 6.80665 79.6698 6.82158C79.4048 6.83651 79.1716 6.8589 78.9664 6.88689C78.7313 6.91674 78.5111 6.94473 78.3059 6.97458C78.3357 7.06228 78.3712 7.18729 78.4159 7.34775C78.4458 7.48023 78.4738 7.65188 78.5036 7.86459C78.5335 8.0773 78.5484 8.33105 78.5484 8.62399V15.9512H74.8951V8.62399C74.8951 8.16872 74.854 7.81794 74.7738 7.56792C74.6936 7.3179 74.5686 7.13877 74.3988 7.02869C74.2308 6.9186 74.0256 6.8533 73.783 6.83091C73.5405 6.80852 73.2587 6.79732 72.9359 6.79732C72.6281 6.79732 72.3501 6.80479 72.1 6.81971C71.85 6.83464 71.6448 6.84957 71.4843 6.8645C71.294 6.87942 71.1242 6.89435 70.9787 6.90928V15.9531H67.3048V5.17217C67.7302 5.08447 68.2284 5.01171 68.8012 4.952C69.2845 4.89416 69.876 4.83818 70.5719 4.7878C71.2697 4.73743 72.0571 4.7113 72.9378 4.7113C73.436 4.7113 73.8651 4.72623 74.2252 4.75608C74.5854 4.78594 74.897 4.81766 75.16 4.85497C75.425 4.89229 75.647 4.92774 75.8317 4.96506C76.0146 5.00238 76.1657 5.02663 76.2833 5.04156C76.5613 5.01171 77.0651 4.95013 77.7909 4.85497C78.5167 4.75982 79.431 4.7113 80.53 4.7113C81.4536 4.7113 82.2689 4.74489 82.9724 4.81019C83.6758 4.8755 84.2635 5.04156 84.7319 5.30464C85.202 5.56959 85.5528 5.96142 85.7879 6.482C86.023 7.00257 86.1406 7.71719 86.1406 8.62772V15.9549H82.4891L82.4854 15.9531Z"
      class="fill-logo-white"
    />
    <path
      d="M61.0543 3.10291V0H64.7282V3.10291H61.0543Z"
      class="fill-logo-white"
    />
    <path
      d="M61.0543 15.9531V4.97253H64.7282V15.9531H61.0543Z"
      class="fill-logo-white"
    />
    <path
      d="M59.2722 15.7552C58.9494 15.8578 58.5743 15.9381 58.1489 15.9978C57.7814 16.0706 57.3429 16.1303 56.8279 16.1732C56.3148 16.218 55.7271 16.2385 55.0684 16.2385C54.2754 16.2385 53.613 16.1732 53.0776 16.0407C52.5421 15.9082 52.1166 15.6881 51.8013 15.3802C51.486 15.0723 51.2583 14.6656 51.1184 14.1599C50.9785 13.6543 50.9094 13.0348 50.9094 12.2997V7.08462H48.7096V4.97248H50.9094V1.23145H54.5628V4.97248H58.6117V7.08462H54.5628V12.2997C54.5628 12.6952 54.5777 13.0143 54.6076 13.2569C54.6374 13.4994 54.7176 13.686 54.8501 13.8185C54.9826 13.9509 55.1841 14.0349 55.4546 14.0722C55.7252 14.1095 56.1114 14.1282 56.6096 14.1282C57.0201 14.1282 57.3877 14.1207 57.7105 14.1058C58.0332 14.0909 58.3113 14.0685 58.5464 14.0405C58.8113 14.0107 59.052 13.9827 59.2722 13.9528V15.7571V15.7552Z"
      class="fill-logo-white"
    />
    <path
      d="M43.6722 15.953L40.657 11.419H39.1624V15.953H35.4867V0H39.1624V9.30686H40.657L43.5621 4.97249H47.6987L43.98 10.3629L47.8536 15.953H43.6722Z"
      class="fill-logo-white"
    />
    <path
      d="M29.2375 15.953V0H32.9133V15.953H29.2375Z"
      class="fill-logo-white"
    />
    <path
      d="M8.69113 3.69621V15.953H4.66463V3.69621H0V0.990723H13.3334V3.69621H8.69113Z"
      class="fill-logo-white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7588 7.97833H21.0222C20.7535 5.96881 19.1339 4.82318 16.1653 4.5433C16.1653 3.7018 16.1653 2.51699 16.1653 0.990723C23.5597 1.16052 27.0992 4.08617 26.7839 9.76955V14.1636C26.7839 15.1488 25.9779 15.9548 24.9927 15.9548H17.7569C16.7717 15.9548 15.9657 15.1488 15.9657 14.1636V9.76955C15.9657 8.78438 16.7717 7.97833 17.7569 7.97833H17.7588Z"
      fill="#00CEBE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M98.1323 13.0198H94.7309C95.0126 15.1151 96.6993 16.3074 99.7929 16.5985C99.7929 17.4754 99.7929 18.7106 99.7929 20.3003C92.087 20.1231 88.3982 17.0743 88.7266 11.152V6.57325C88.7266 5.54703 89.5662 4.7074 90.5924 4.7074H98.1342C99.1604 4.7074 100 5.54703 100 6.57325V11.152C100 12.1783 99.1604 13.0179 98.1342 13.0179L98.1323 13.0198Z"
      fill="#00CEBE"
    />
  </svg>
</template>

<script>
export default {
  name: "logo-svg",
};
</script>
<style lang="scss">
.theme--light {
  .fill-logo-white {
    fill: var(--v-primary-base);
  }
}
.theme--dark {
  .fill-logo-white {
    fill: white;
  }
}
</style>
