import { mapGetters, mapMutations } from "vuex";

export default {
  computed: {
    ...mapGetters("disorder", ["disorders"]),
    ...mapGetters(["lang"]),
    ...mapGetters("therapist", ["demoTherapist"]),
  },

  methods: {
    ...mapMutations("therapist", ["setDemoTherapist"]),

    therapistCategoryTitle(therapist) {
      if (!therapist?.therapistInfo?.categoryTitle) return "";
      return therapist?.therapistInfo?.categoryTitle
        ?.map((c) => {
          if (c?.name) {
            return this.$t(c?.name.trim());
          } else {
            return this.$t(c.trim());
          }
        })
        .join(", ");
    },

    therapistBio(therapist) {
      return this.lang === "ar"
        ? therapist?.profileAr?.bio || therapist?.profile?.bio
        : therapist?.profile?.bio;
    },

    therapistLanguageSpeak(therapist) {
      return therapist?.therapistInfo?.languageSpeak || [];
    },

    therapistEducation(therapist) {
      return therapist?.therapistInfo?.education || null;
    },

    therapistEducationInstitution(education) {
      return this.lang === "ar"
        ? education?.institutionAr || education?.institution
        : education?.institution;
    },

    therapistEducationDegreeName(education) {
      return this.lang === "ar"
        ? education?.degreeNameAr || education?.degreeName
        : education?.degreeName;
    },

    therapistExperienceYears(therapist) {
      return therapist?.therapistInfo?.experienceYears || null;
    },

    therapistTrainingCertification(therapist) {
      return therapist?.therapistInfo?.trainingCertification || null;
    },

    therapistSpeciality(therapist, category) {
      const therapistDisorders = therapist?.therapistDisorders;
      if (!therapistDisorders) {
        return null;
      }
      return therapistDisorders
        .map((id) => {
          return this.disorders.find(
            (dis) => dis._id === id && dis.category === category
          )?.name;
        })
        .filter((e) => e);
    },
  },
};
