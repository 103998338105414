<template>
  <v-container class="px-0">
    <v-progress-circular
      v-if="loading"
      :size="70"
      :width="7"
      color="primary"
      indeterminate
    ></v-progress-circular>

    <!--    <v-card-->
    <!--      v-else-if="!itemsCount"-->
    <!--      class="card-spacing rounded-card-with-shadow mx-auto pb-6"-->
    <!--    >-->
    <!--      &lt;!&ndash; <v-toolbar color="primary" elevation="0" dark>-->
    <!--        <logoSvg />-->
    <!--      </v-toolbar> &ndash;&gt;-->

    <!--      <v-card-text class="pa-8 text-center">-->
    <!--        <p>-->
    <!--          {{-->
    <!--            $t(-->
    <!--              "Unfortunately, we were unable to match you with a suitable therapist at the moment. We understand this must be upsetting for you."-->
    <!--            )-->
    <!--          }}-->
    <!--        </p>-->
    <!--        <p>-->
    <!--          {{-->
    <!--            $t(-->
    <!--              "Rest assured, our matching advisor will get in touch with you via email within 48 hours in order to further assist you in finding the right therapist and getting you started."-->
    <!--            )-->
    <!--          }}-->
    <!--        </p>-->
    <!--      </v-card-text>-->
    <!--      <v-card-actions class="pb-5">-->
    <!--        <v-spacer></v-spacer>-->
    <!--        <v-btn-->
    <!--          color="primary"-->
    <!--          class="px-13 py-3 text-none blue-shadow"-->
    <!--          elevation="0"-->
    <!--          @click="logout"-->
    <!--        >-->
    <!--          {{ $t("Continue") }}-->
    <!--        </v-btn>-->
    <!--        <v-spacer></v-spacer>-->
    <!--      </v-card-actions>-->
    <!--    </v-card>-->
    <v-card
      v-else-if="!itemsCount"
      class="mx-auto pb-6 rounded therapist-card fill-height d-flex flex-column"
      style="max-width: 300px"
    >
      <div class="d-flex px-lg-5 px-8 pt-5">
        <div
          style="background: #f8af2f; width: 70px; height: 70px"
          class="d-flex align-items-center justify-content-center rounded-circle"
        >
          <v-icon color="#000" :size="40">mdi-phone-in-talk</v-icon>
        </div>
      </div>
      <div class="mt-5 px-lg-5 px-8 text-start">
        <h2 class="primary--text">
          {{ $t("Therapist matching consultation") }}
        </h2>
        <p class="body-2 mt-6 mb-4">
          {{
            $t(
              "Begin your therapy journey with an initial therapist matching consultation. Discuss symptoms, goals, availability, and get matched appropriately."
            )
          }}
        </p>
        <v-divider></v-divider>
        <a
          href="mailto:customercare@talktime.ae"
          class="me-6 mb-3 mt-5 d-block body-2"
          ><v-icon color="primary">mdi-email-outline</v-icon
          ><span class="ps-2">customercare@talktime.ae</span></a
        >
        <a
          href="tel:+971048369411"
          class="body-2 mb-4 mt-3 d-block"
          target="_blank"
        >
          <v-icon color="primary" style="text-decoration: none"
            >mdi-phone</v-icon
          >
          <span class="ms-2">{{ $t("+971048369411") }}</span></a
        >
        <v-btn @click="onRetake" color="primary">{{
          $t("Resubmit initial assessment")
        }}</v-btn>
      </div>
    </v-card>

    <div v-else>
      <v-row
        class="pb-2"
        v-if="$route.name !== 'TherapistSelection'"
        align="center"
      >
        <v-col cols="auto">
          <v-btn
            @click="$router.go(-1)"
            plain
            :ripple="false"
            color="accent"
            small
            outlined
            icon
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <h2 class="headline font-weight-medium">
            {{ $t("My Therapist") }}
          </h2>
        </v-col>
      </v-row>
      <v-divider v-if="$route.name !== 'TherapistSelection'"></v-divider>

      <carousel
        :autoplay="false"
        @navigation-click="navigationHandler"
        :rtl="$vuetify.rtl"
        :mouseDrag="false"
        style="max-width: 1068px"
        :style="
          $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
            ? 'width: 100%;'
            : 'width: 75vw; width: calc(100% - 80px)'
        "
        class="mx-auto pb-md-15 pb-0"
        :paginationEnabled="false"
        :navigationEnabled="true"
        easing="ease"
        :perPageCustom="[
          [375, 1],
          [800, 2],
          [1440, 3],
        ]"
        :touchDrag="false"
        navigationNextLabel="<i data-v-653eb36d='' aria-hidden='true' class='v-icon notranslate  v-icon--left mdi mdi-chevron-right theme--light navigation-arrow' style='font-size:40px'></i>"
        navigationPrevLabel="<i data-v-653eb36d='' aria-hidden='true' class='v-icon notranslate  v-icon--right mdi mdi-chevron-left theme--light navigation-arrow' style='font-size:40px'></i>"
      >
        <slide
          v-for="(therapist, i) in therapistsData"
          :width="
            $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '' : '382px'
          "
          class="px-sm-4 px-2"
          :key="i"
          style="margin-top: 10px"
          :style="
            $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
              ? 'min-width: 100%'
              : ''
          "
        >
          <TherapistCard
            ref="therapistCard"
            :therapist="therapist"
            :i="i"
            :therapistsData="therapistsData"
            @openTherapistProfile="openTherapistProfile"
            @selectTherapist="selectTherapist"
          />
        </slide>
      </carousel>
    </div>

    <!-- <ViewTherapistProfile
      ref="viewTherapistProfileRef"
      :itemsCount="itemsCount"
      :therapistIndex.sync="therapistIndex"
      :therapist="therapist"
      @closed="defaultTherapist"
      @onSelect="selectTherapist(therapist)"
    /> -->

    <ChangeTherapistReason
      ref="ChangeTherapistReasonRef"
      :reason.sync="changeTherapistReason"
      @addReason="onAddReason"
    />

    <ChangeTherapistConfirm
      ref="ChangeTherapistConfirmRef"
      :selected-therapist="demoTherapist"
      @confirmChangeTherapist="onConfirmChangeTherapist"
    />

    <ChangeTherapistPrivacy
      :selected-therapist="demoTherapist"
      ref="ChangeTherapistPrivacyRef"
    />
  </v-container>
</template>

<script>
/* eslint-disable */
import ChangeTherapistReason from "@/components/Client/ChangeTherapistReason";
import ChangeTherapistConfirm from "@/components/Client/ChangeTherapistConfirm";
import ChangeTherapistPrivacy from "@/components/Client/ChangeTherapistPrivacy";
// import ViewTherapistProfile from "@/components/Client/ViewTherapistProfile";

import TherapistCard from "@/components/Client/TherapistCard";
import TherapistMixin from "@/mixins/TherapistMixin";
import UserMixin from "@/mixins/UserMixin";

// import logoSvg from "@/components/svg/Logo.vue";
import { Carousel, Slide } from "vue-carousel";
import { mapActions } from "vuex";
import { eventBus } from "@/main";
import { mapGetters } from "vuex";

export default {
  name: "TherapistCards",
  components: {
    TherapistCard,
    ChangeTherapistReason,
    ChangeTherapistConfirm,
    ChangeTherapistPrivacy,
    // ViewTherapistProfile,
    // logoSvg,
    Carousel,
    Slide,
  },

  mixins: [UserMixin, TherapistMixin],
  props: {
    goToPricing: {
      type: Boolean,
      default: false,
    },
    changeTherapistList: {
      default: false,
      type: Boolean,
    },
  },

  data: () => {
    return {
      loading: true,
      model: null,
      therapistsData: [],
      changeTherapist: false,
      // therapist: null,
      // showMoreCount: 0,
      // showMoreBtn: false,
      therapistIndex: null,
      fromItem: 0,
      itemsPerStep: 3,

      changeTherapistReason: "",
    };
  },

  computed: {
    ...mapGetters({
      getSponserType: "getSponserType",
    }),
    therapist() {
      if (Number.isInteger(this.therapistIndex)) {
        return this.therapistsData[this.therapistIndex];
      }

      return null;
    },

    itemsCount() {
      return this?.therapistsData?.length || 0;
    },
    // shownTherapistsData() {
    //     if (!this.itemsCount) return []
    //     return this.therapistsData.slice(this.fromItem, this.fromItem + this.itemsPerStep)
    // },

    // shownMoreBtn() {
    //   return this.itemsPerStep >= this.itemsCount
    // },
  },

  methods: {
    ...mapActions("therapist", [
      "getTherapistForSelection",
      "changeTherapistForUser",
      "addTherapistToUserCompany",
    ]),
    ...mapActions("user", ["logout"]),

    onRetake() {
      if (this.me.therapyType === "Teen Therapy") {
        this.$router.push({
          name: "TeenTherapyReasonsRetake",
          query: { next: "therapist-selection" },
        });
      } else {
        this.$router.push({
          name: "PrimaryReasonsRetake",
          query: { next: "therapist-selection" },
        });
      }
    },

    // changeTherapistMethod() {
    //   this.$emit("update:changeTherapistList", false);
    // },

    openTherapistProfile(index) {
      this.therapistIndex = index;
      // this.$refs.viewTherapistProfileRef.open();
    },

    defaultTherapist() {
      this.therapistIndex = null;
    },

    // viewMore() {
    //   this.itemsPerStep = this.itemsPerStep + 3;
    // },

    selectTherapist(therapist) {
      this.setDemoTherapist(therapist);

      if (this.goToPricing) {
        if (this.me.company) {
          //TODO: change logic
          this.loading = true;
          this.addTherapistToUserCompany({
            id: therapist._id,
          }).then(() => {
            this.$nextTick(async () => {
              this.loading = false;
              await this.getMe();
              await this.$router.replace({ name: "Calendar" });
              // this.$refs.ChangeTherapistPrivacyRef.dialog = true;
            });
          });
        } else {
          this.$router.push({
            name: "Pricing",
          });
        }
      } else {
        this.$refs.ChangeTherapistReasonRef.dialog = true;
        this.selectedTherapist = therapist;
      }
      // eventBus.$emit("pricingDialog:action", true);
      // this.$router.push({ name: "Calendar" });
    },
    navigationHandler() {
      this.$refs.therapistCard.map((i) => i.closeMore());
    },

    onAddReason() {
      this.$refs.ChangeTherapistConfirmRef.dialog = true;
    },

    onConfirmChangeTherapist() {
      if (this.myTherapistId !== this.selectedTherapist._id) {
        this.loading = true;

        this.changeTherapistForUser({
          id: this.selectedTherapist._id,
          reason: this.changeTherapistReason,
        }).then(() => {
          this.loading = false;
          this.$nextTick(() => {
            this.$refs.ChangeTherapistPrivacyRef.dialog = true;
          });
        });
      }
    },
  },

  async mounted() {
    this.awaitPromiseCallback({
      key: "getMe",
      cb: async () => {
        this.loading = true;
        this.therapistsData = await this.getTherapistForSelection();
        this.loading = false;
      },
    });
  },
};
</script>

<style scoped>
.VueCarousel-slide {
  max-width: 100%;
}
/* .card-with-animation .v-avatar::v-deep {
  height: 120px;
  min-width: 120px;
  width: 120px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
} */
</style>
