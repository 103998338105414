<template>
  <v-dialog v-model="dialog" persistent width="700">
    <v-card class="rounded-lg">
      <v-btn @click="dialog = false" elevation="2" icon class="close-icon pa-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-card-text class="pa-8">
        <p class="text-center mb-0 mt-4">
          {{ $t("Are you sure you want to change your current therapist") }}
        </p>
        <p class="text-center secondary--text font-weight-bold">
          {{ getUserName(myTherapist) }}
        </p>
        <!-- TODO: Translate -->
        <p class="text-center mt-4 mb-0">Your new therapist</p>
        <p class="text-center secondary--text font-weight-bold">
          {{ getUserName(selectedTherapist) }}
        </p>
      </v-card-text>

      <v-card-actions class="pb-6">
        <v-spacer></v-spacer>
        <v-btn color="primary" class="px-8 me-3" large @click="changeConfirm">
          {{ $t("Yes") }}
        </v-btn>
        <v-btn
          color="primary"
          outlined
          class="px-8"
          @click="dialog = false"
          large
        >
          {{ $t("No") }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import UserMixin from "@/mixins/UserMixin";

export default {
  name: "ChangeTherapistConfirm",

  mixins: [UserMixin],

  props: {
    selectedTherapist: {
      required: true,
    },
  },

  data: () => {
    return {
      dialog: false,
    };
  },

  methods: {
    changeConfirm() {
      this.$emit("confirmChangeTherapist");
      this.dialog = false;
    },
  },
};
</script>
