<template>
  <div
    :class="
      $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '' : 'hp-body-bg'
    "
    class="d-flex flex-column"
    style="min-height: 100vh"
  >
    <NoUserNavbar :back-button-action="backButtonAction" />

    <v-container class="text-center container-spacing">
      <!-- <h2 class="dark--text display-1 font-weight-bold">

      </h2> -->

      <h6 class="headline font-weight-semibold text-center">
        {{ $t("Choose Your Therapist") }}
      </h6>
      <p
        class="subtitle-1 dark--text mt-4 mb-lg-6 mb-4 mx-auto"
        :style="
          $vuetify.breakpoint.xs || $vuetify.breakpoint.sm
            ? ''
            : 'max-width: 50%'
        "
      >
        {{ $t("Get matched from a list of most suitable recommendations") }}
      </p>

      <TherapistCards goToPricing />
    </v-container>
    <NoUserFooter />
  </div>
</template>

<script>
// import ChangeLanguage from "@/components/layout/ChangeLanguage";
// import Logout from "@/components/layout/Logout"
import TherapistCards from "@/components/Client/TherapistCards";
// import { mapActions } from "vuex";

import NoUserNavbar from "@/components/layout/NoUserNavbar";
import NoUserFooter from "@/components/layout/NoUserFooter";

import UserMixin from "@/mixins/UserMixin";
import { mapActions } from "vuex";

export default {
  name: "TherapistSelection",
  components: {
    TherapistCards,
    // ChangeLanguage,
    // Logout,
    NoUserNavbar,
    NoUserFooter,
  },
  mixins: [UserMixin],

  methods: {
    ...mapActions("user", ["logout"]),

    backButtonAction() {
      this.$router.push({ name: "FitAssessmentQuestionsRetake" });
    },
  },

  mounted() {
    this.awaitPromiseCallback({
      key: "getMe",
      cb: async () => {
        if (this.hasNoTherapist) {
          await this.$router.replace({ name: "Profile" });
        }
      },
    });
  },
};
</script>

<style scoped>
/* .theme--light .hp-body-bg {
  box-shadow: inset 0 0 0 2000px rgba(255, 255, 255, 0.5);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-image: url("../../../assets/main.png");
} */
.theme--light .hp-body-bg {
  background-position: top left, top right;
  /* background-attachment: fixed; */
  background-image: url("../../../assets/background-gradient-left.png"),
    url("../../../assets/background-gradient-right.png");
}
</style>
