<template>
  <v-dialog v-model="dialog" persistent width="700">
    <v-card class="rounded-lg">
      <v-btn elevation="2" icon class="close-icon pa-0" @click="closeDialog">
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-card-text class="pa-8">
        <p class="text-center mb-0">
          Please describe your reason(s) for changing therapists. Your feedback
          will help us improve our services.
        </p>

        <v-textarea
          outlined
          class="mt-5"
          hide-details
          counter
          :value="reason"
          placeholder="Type here..."
          @input="(v) => $emit('update:reason', v)"
        />
      </v-card-text>

      <v-card-actions class="pb-6">
        <v-spacer></v-spacer>
        <v-btn color="primary" class="px-8 me-3" large @click="addReason">
          {{ $t("Continue") }}
        </v-btn>
        <v-btn color="primary" outlined class="px-8" @click="closeDialog" large>
          {{ $t("Cancel") }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ChangeTherapistReason",

  props: {
    reason: {
      required: true,
    },
  },

  data: () => {
    return {
      dialog: false,
    };
  },

  // computed: {
  //   rules() {
  //     return [
  //       v => !!v || "This field is required",
  //       v => (v && v.length >= 20) || "Minimum 20 characters",
  //       v => (v && v.length <= 500) || "This field exceeds maximum allowed characters",
  //     ]
  //   },
  // },

  methods: {
    addReason() {
      this.$emit("addReason", this.reason);
      this.dialog = false;
    },

    closeDialog() {
      this.$emit("update:reason", "");
      this.dialog = false;
    },
  },
};
</script>
